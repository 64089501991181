<template>
	<div id="app">
		<div v-if="loading" class="loader___U0gXU fullScreen___3stt8">
			<div class="warpper___1_Ml1">
				<div class="inner___LZ9-r"></div>
				<div class="text___27oz3">LOADING</div>
			</div>
		</div>
		<cube-view v-if="!loading" :parent="$route.meta.parent"></cube-view>
	</div>
</template>

<script>
	import CubeView from '@/components/cube-view/index.vue'
	import {setLocal, getLocal, getSession} from './config/auth'
	
	export default {
		components: {CubeView},
		data() {
			return {
				CODE: '',
				Token: {},
				User: {},
				loading: true
			}
		},
		methods: {
			setMapSize() {
				let height = document.documentElement.clientHeight
				// document.getElementById('app').style.height = height + 'px'
				if (document.getElementById('map')) {
					document.getElementById('map').style.height = height + 'px'
				}
				if (document.getElementById('listContent')) {
					document.getElementById('listContent').style.height = height + 'px'
				}
			},
			isWeiXinAndIos() {
				// window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
				let ua = '' + window.navigator.userAgent.toLowerCase()
				// 通过正则表达式匹配ua中是否含有MicroMessenger字符串且是IOS系统
				let isWeixin = /MicroMessenger/i.test(ua) // 是在微信浏览器
				let isIos = /\(i[^;]+;( U;)? CPU.+Mac OS X/i.test(ua) // 是IOS系统
				return isWeixin && isIos
			},
			/*getUserInfo() {
				let that = this
				let hasused = getSession('hasused')
				if (hasused) {
					return false
				}
				that.util.axios({
					url: that.config.server.getuserurl,
					data: {code: that.CODE},
					success: function (res) {
						getSession('hasused', true)
						let User = res.data
						// alert(JSON.stringify(User))
						if (User) {
							User.openID = User.openid ? User.openid : that.User.openID
							User.weChatNickName = User.weChatNickName ? User.weChatNickName : that.User.weChatNickName
							User.weChatImgUrl = User.weChatImgUrl ? User.weChatImgUrl : that.User.weChatImgUrl
							User.imUserSig = User.imUserSig ? User.imUserSig : that.User.imUserSig
						}
						that.User = User
						setLocal('wechat_user', JSON.stringify(that.User))
						// alert(JSON.stringify(that.User))
						that.loading = false;
					},
					error: function (err) {
						console.error(err)
						getSession('hasused', true)
						that.loading = false
						if (!that.User||!that.User.openID) {
							alert('授权失败！')
						}
					}
				})
			}*/
		},
		mounted: function () {
			let that = this
			let href = location.href
			let userInfo = getLocal('wechat_user')
			that.User = userInfo ? JSON.parse(userInfo) : false
			if (href.indexOf('path=') === -1) {
				/*/!*let search = href.split('code=')
				search = search.length > 1 ? search[1] : ''
				let code = search.split('&')[0]
				code = code.split('#')[0]
				code = code.split('/')[0]*!/
				let code = getSession('code')
				that.CODE = code
				// alert(code)
				if (!code) {
					that.loading = false
					alert('未授权')
				} else {
					that.getUserInfo()
				}*/
				that.setMapSize()
				window.onresize = function () {
					that.setMapSize()
				}
				that.loading = false
			} else {
				let search = location.search
				search = search ? search.split('?')[1] : ''
				let params = search ? search.split('&') : []//参数内容
				let pathname = 'match'
				let paramname = ''
				for (let i = 0; i < params.length; i++) {
					let item = params[i].split('=')
					if (item[0] === 'path') {
						pathname = item[1]
					} else if (item[0] === 'param') {
						paramname = item[1]
					}
				}
				let path = paramname ? pathname + 'i' + paramname : pathname

				let bashLink = location.protocol+'//' + location.host+location.pathname
				bashLink = encodeURIComponent(bashLink)

				location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx369fcdb40635baf3&redirect_uri='+bashLink+'&response_type=code&scope=snsapi_userinfo&state=' + path + '#wechat_redirect'
			}
			
			// 兼容部分ios手机input失焦后页面上移问题
			let myFunction
			let isWXAndIos = this.isWeiXinAndIos()
			if (isWXAndIos) { // 既是微信浏览器 又是ios============（因为查到只有在微信环境下，ios手机上才会出现input失去焦点的时候页面被顶起）
				document.body.addEventListener('focusin', () => { // 软键盘弹起事件
					clearTimeout(myFunction)
				})
				document.body.addEventListener('focusout', () => { // 软键盘关闭事件
					clearTimeout(myFunction)
					myFunction = setTimeout(function () {
						window.scrollTo({top: 0, left: 0, behavior: 'smooth'})// 重点  =======当键盘收起的时候让页面回到原始位置
					}, 200)
				})
			}
		}
	}
</script>

<style lang="scss">
	#app {
		font-family: PingFangSC-Medium, 'Avenir', Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: left;
		/*color: #2c3e50;*/
		color: #333333;
		/*position: relative;*/
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		line-height: 1.6;
	}
	
	#nav {
		padding: 30px;
		
		a {
			font-weight: bold;
			color: #2c3e50;
			
			&.router-link-exact-active {
				color: #4a90e2;
			}
		}
	}
	
	.loader___U0gXU {
		background-color: #fff;
		width: 100%;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		z-index: 100000;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-pack: center;
		justify-content: center;
		-ms-flex-align: center;
		align-items: center;
		opacity: 1;
		text-align: center;
		
		&.fullScreen___3stt8 {
			position: fixed;
		}
		
		&.hidden___2sYXU {
			z-index: -1;
			opacity: 0;
			transition: opacity 1s ease .5s, z-index .1s ease 1.5s;
		}
		
		.inner___LZ9-r {
			width: 40px;
			height: 40px;
			margin: 0 auto;
			text-indent: -12345px;
			border: 1px solid rgba(0, 0, 0, .08);
			border-left-color: rgba(0, 0, 0, .7);
			border-radius: 50%;
			z-index: 100001;
			animation: spinner___2-IFt .6s linear infinite;
		}
		
		.warpper___1_Ml1 {
			width: 100px;
			height: 100px;
			display: -ms-inline-flexbox;
			display: inline-flex;
			-ms-flex-direction: column;
			flex-direction: column;
			-ms-flex-pack: distribute;
			justify-content: space-around;
		}
		
		.text___27oz3 {
			width: 100px;
			height: 20px;
			text-align: center;
			font-size: 12px;
			letter-spacing: 4px;
			color: #000;
		}
	}
	
	@keyframes spinner___2-IFt {
		0% {
			transform: rotate(0deg)
		}
		to {
			transform: rotate(1turn)
		}
	}
</style>
