import axios from 'axios'

// let env = process.env.VUE_APP_CURRENTMODE
let env = 'test'
let config = {
  // 'wy': 'http://10.10.254.44:5000/api/wechat-service',
  // 'dev': 'http://10.30.0.104:5000/api/wechat-service/',
  // 'test': 'https://utrip-service-test.cmfun.cn:4433/api/wechat-service',
  // 'production': 'https://utrip-service.cmfun.cn/api/wechat-service',
  // 'test': 'https://www.beidouutrail.com/api/wechat-service',
  // 'production': 'https://www.beidouutrail.com/api/wechat-service',
  // 'test': 'https://test-api.beidouutrail.com/api/wechat-service',
  'test': 'https://api.beidouutrail.com/api/wechat-service',
}
// let aliurl = 'https://oss-jgwl.cmfun.cn/'
let bashLink = location.protocol+'//' + location.host+location.pathname + '?path='
// console.log(bashLink)
let wxShare = {
  appId: 'wx369fcdb40635baf3',
  debug: false,
  /**onMenuShareTimeline（即将废弃）
   * onMenuShareAppMessage（即将废弃）
   * onMenuShareQQ（即将废弃）
   * 'hideMenuItems',(隐藏分享按钮)*/
  jsApiList: [ 'updateAppMessageShareData', 'updateTimelineShareData', 'onMenuShareTimeline', 'onMenuShareAppMessage', 'onMenuShareQQ', 'onMenuShareWeibo', 'onMenuShareQZone'],
  openTagList: ['wx-open-launch-weapp'], // 微信开放标签 小程序跳转按钮：<wx-open-launch-weapp>
  shareinfo: {
    title: 'UTrail星跑·北斗卫通直播', // 分享标题
    desc: 'UTrail星跑·北斗卫通，伴你同行', // 分享描述
    link: 'match',  // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
    imgUrl: 'https://utrail-oss.oss-cn-beijing.aliyuncs.com/server_2023-04-11T07-17-54.150Z.png' // 分享图标
    // imgUrl: aliurl + 'server_2018-06-19_17-00-59_877037.png_119x119' // 分享图标
  },
  // baseLink: 'http://uttrip.cmfun.cn/wechath5/?path=',
  baseLink: bashLink,
  init: function () {
    this.getJsapiTicket()
  },
  getJsapiTicket: function () {
    axios({
      method: 'POST',
      baseURL: config[env] ? config[env] : config.test,
      url: '/matchLive/getWeChatApiTicket',
      withCredentials: true,
      proxy: false
    }).then((res) => {
      if (res.data.code === '0') {
        if (res.data.data && res.data.data.ticket) {
          wxShare.getConfig(res.data.data.ticket)
        }
      }
    }).catch((err) => {
      console.error(err)
    })
    // wxShare.getConfig('HoagFKDcsGMVCIY2vOjf9gfxjXE87xoSpWmgTZz-2nABwElRDpM_wkSM9yo6vqLekz78P3IsGOsuCxR4AzKbxg')
  },
  getNonceStr: function (randomFlag, min, max) {
    let str = "",
      range = min,
      arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    // 随机产生
    if (randomFlag) {
      range = Math.round(Math.random() * (max - min)) + min
    }
    for (let i = 0; i < range; i++) {
      let pos = Math.round(Math.random() * (arr.length - 1))
      str += arr[pos]
    }
    return str
  },
  getConfig: function (jsapi_ticket) {
    let nonceStr = wxShare.getNonceStr(false, 10)
    let timestamp = (new Date().getTime() - 1000).toString().substring(0, 10)
    let url = window.location.href.split("#")[0]

    let string1 = 'jsapi_ticket=' + jsapi_ticket + '&noncestr=' + nonceStr + '&timestamp=' + timestamp + '&url=' + url
    let signature = hex_sha1(string1)
    let config = {
      debug: wxShare.debug, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: wxShare.appId, // 必填，公众号的唯一标识
      timestamp: timestamp, // 必填，生成签名的时间戳
      nonceStr: nonceStr, // 必填，生成签名的随机串
      signature: signature,// 必填，签名
      jsApiList: wxShare.jsApiList, // 必填，需要使用的JS接口列表
      openTagList: wxShare.openTagList
    }
    wxShare.setWxShareInfo(config)
    // console.log(wxShare)
  },
  setWxShareInfo: function (config) {
    const self = this
    wx.config(config)
    wx.ready(function () {
      // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
      wx.checkJsApi({
        jsApiList: wxShare.jsApiList, // 需要检测的JS接口列表，所有JS接口列表见附录2,
        success: function (res) {
          // 以键值对的形式返回，可用的api值true，不可用为false
          // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
          // console.log(res)
        }
      })
      //隐藏分享按钮
      // wx.hideMenuItems({
      //   menuList: ['menuItem:share:appMessage',"menuItem:share:timeline"] // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮，所有menu项见附录3
      // })
      //需在用户可能点击分享按钮前就先调用
      wx.updateAppMessageShareData({
        title: wxShare.shareinfo.title, // 分享标题
        desc: wxShare.shareinfo.desc, // 分享描述
        link: wxShare.baseLink + wxShare.shareinfo.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        // link: wxShare.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: wxShare.shareinfo.imgUrl, // 分享图标
        success: function () {
          // 设置成功
        }
      })
      //需在用户可能点击分享按钮前就先调用
      wx.updateTimelineShareData({
        title: wxShare.shareinfo.title, // 分享标题
        link:  wxShare.baseLink + wxShare.shareinfo.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        // link: wxShare.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: wxShare.shareinfo.imgUrl, // 分享图标
        success: function () {
          // 设置成功
        }
      })
      //获取“分享到朋友圈”按钮点击状态及自定义分享内容接口（即将废弃）
      wx.onMenuShareTimeline({
        title: wxShare.shareinfo.title, // 分享标题
        link:  wxShare.baseLink + wxShare.shareinfo.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        // link: wxShare.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: wxShare.shareinfo.imgUrl, // 分享图标
        success: function () {
          console.log("分享到朋友圈:")
          // 用户点击了分享后执行的回调函数
        }
      })
      // 获取“分享给朋友”按钮点击状态及自定义分享内容接口（即将废弃）
      wx.onMenuShareAppMessage({
        title: wxShare.shareinfo.title, // 分享标题
        desc: wxShare.shareinfo.desc, // 分享描述
        link:  wxShare.baseLink + wxShare.shareinfo.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        // link: wxShare.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: wxShare.shareinfo.imgUrl, // 分享图标
        success: function () {// 用户点击了分享后执行的回调函数
          console.log("分享给朋友:")
        }
      })
      //获取“分享到QQ”按钮点击状态及自定义分享内容接口
      wx.onMenuShareQQ({
        title: wxShare.shareinfo.title, // 分享标题
        desc: wxShare.shareinfo.desc, // 分享描述
        link:  wxShare.baseLink + wxShare.shareinfo.link, // 分享链接
        // link: wxShare.link, // 分享链接
        imgUrl: wxShare.shareinfo.imgUrl, // 分享图标
        success: function () {
          // 用户确认分享后执行的回调函数
          console.log("分享到QQ:")
        },
        cancel: function () {
          // 用户取消分享后执行的回调函数
        }
      })
      //获取“分享到腾讯微博”按钮点击状态及自定义分享内容接口
      wx.onMenuShareWeibo({
        title: wxShare.shareinfo.title, // 分享标题
        desc: wxShare.shareinfo.desc, // 分享描述
        link:  wxShare.baseLink + wxShare.shareinfo.link, // 分享链接
        // link: wxShare.link, // 分享链接
        imgUrl: wxShare.shareinfo.imgUrl, // 分享图标
        success: function () {// 用户确认分享后执行的回调函数
          console.log("分享到腾讯微博:")
        },
        cancel: function () {// 用户取消分享后执行的回调函数
        }
      })
      //获取“分享到QQ空间”按钮点击状态及自定义分享内容接口
      wx.onMenuShareQZone({
        title: wxShare.shareinfo.title, // 分享标题
        desc: wxShare.shareinfo.desc, // 分享描述
        link:  wxShare.baseLink + wxShare.shareinfo.link, // 分享链接
        // link: wxShare.link, // 分享链接
        imgUrl: wxShare.shareinfo.imgUrl, // 分享图标
        success: function () {// 用户确认分享后执行的回调函数
          console.log("分享到QQ空间:")
        },
        cancel: function () {// 用户取消分享后执行的回调函数
        }
      })
    })
    wx.error(function (res) {
      console.error("config信息验证失败", res);
    })
  },
}
export default wxShare
