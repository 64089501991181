let env = process.env.VUE_APP_CURRENTMODE
let config = {
  //旧地址
  // 'test': 'http://test.beidouutrail.com/api/wechat-service',
  // 'test': 'https://www.beidouutrail.com/api/wechat-service',
  //新地址
  // 'test': 'https://test-api.beidouutrail.com/api/wechat-service',
  'test': 'https://api.beidouutrail.com/api/wechat-service',
}
let server = {
  'serverurl': config.test,
  /*阿里图片地址*/
  // 'aliurl': 'https://oss-jgwl.cmfun.cn/',
  'aliurl': 'https://utrail-oss.oss-cn-beijing.aliyuncs.com/',
  /*我的*/
  'getuserurl': '/personalCenter/getWechatInfoByCode',//根据Code获取userInfo
  'mymatchurl': '/personalCenter/getMyMatchByOpenID',//根据openID获取我的赛事列表
  'userrealinfourl': '/personalCenter/getWeChatInfoByOpenID',//根据openID获取获取个人信息
  'updateuserinfourl': '/personalCenter/submitWeChatRealInfo',//上传微信用户真实信息
  /*赛事直播*/
  'matchlisturl': '/matchLive/getMatchList',//获取赛事列表
  'grouplisturl': '/matchLive/getMatchGroupList',//获取赛事组别列表
  'grouplistallurl': '/workerCenter/groupListInfo',//获取赛事组别列表(含工作组)
  'locationallurl': '/workerCenter/personLocationList',//获取人员定位和报警(含工作组)
  'routerurl': '/matchLive/getMatchLineInfo',//获取赛事赛道信息
  'playerurl': '/matchLive/getPlayersPositions',//获取赛事赛道选手定位
  'rankurl': '/matchLive/getPlayerRankTop3',//获取赛事赛道前三名选手
  'searchplayerurl': '/matchLive/searchPlayer',//通过选手姓名或参赛号模糊搜索选手
  'checkinfourl': '/matchLive/checkMatchExplain',//判断赛事介绍是否有数据（暂不用）
  'matchinfourl': '/matchLive/getMatchExplain',//获取赛事介绍
  'playerdetailcoors': '/matchLive/getPlayerLocationListByPlayerID',//获取个人选手定位轨迹
  'playerrank': '/matchLive/getPlayerRankByPlayer',//获取个人选手排名
  'cpserver': '/matchLive/getKeyPointServer',//获取打卡点服务类型
  'servertime': '/matchLive/getServerTime',//获取服务器时间
  'serchcode': '/matchLive/getPlayerByMatchCode',// 根据选手参赛号搜索选手
  'searchlive': '/matchLive/getRealTimeLocationByPlayerId',//通过选手ID查询选手实时位置
  // real
  'reallive': '/matchLive/getPlayerRealTimeData',// 选手位置实时经纬度速度等
  'realroute': '/matchLive/getRouteByMatchGroupId',//
  'realreset': '/matchLive/resetPlayerData',//
  //new
  'matchmenuget': '/matchLive/getMatchMenuByMatchID',//获取赛事信息
  'matchtrackviewget': '/matchLive/getMatchTrackView',//获取赛事赛道信息
  //new人气榜
  'groupstateget': '/matchLive/getThumbsUpStateByMatchID/',//根据赛事编号判断该赛事是否可以点赞(0：不可用，1：可用)，即是否所有组别是否结束
  'thumbsupget': '/matchLive/getThumbsUpList',//获取点赞列表
  'thumbsupset': '/matchLive/thumbsUpToPlayer',//给选手点赞
  /*成绩查询*/
  'scorelisturl': '/achievementController/getAchievement',//赛事成绩查询(成绩正式发布后)
  'realscorelisturl': '/matchLive/getRealTimeAchievement',//赛事成绩查询{matchGroupID}(实时查询)
  'claimscoreurl': '/achievementController/claimAchievement',//赛事成绩认领
  'scorematchlisturl': '/achievementController/getMatchListForAchievement',//获取成绩查询赛事列表
  //new 证书
  'playersearch': '/achievementController/getPlayerInfoByPlayerCode',//通过选手参赛号模糊搜索选手
  'certificateget': '/achievementController/getCompletionCertificateByPlayerCode',//获取完赛证书
  'playerinfourl': '/personalCenter/getPlayerMatchRankInfo',//证书-获取选手信息
  'checkinfoget': '/personalCenter/getPlayerCheckInfo',//获取选手打卡详情

  /*工作人员*/
  'workeraccess': '/workerCenter/workerLogin',//授权登录
  'matchliveurl': '/matchLive/getMatchLive',//获取赛事圈列表
  'sendmatchlive': '/workerCenter/sendMatchLive',//发布赛事圈
  'delmatchlive': '/workerCenter/delMatchLive',//发布赛事圈
  'workerscore': '/workerCenter/achievementManagement',//工作人员成绩管理
  'commentstate': '/workerCenter/getMatchCommentState',//获取赛事评论开关状态
  'commentstateset': '/workerCenter/changeMatchCommentState',//赛事评论开启/关闭
  'matchinfoget': '/workerCenter/getMatchImGroupID',//获取赛事IM编号
  /*微信分享*/
  'wechatticket': '/matchLive/getWeChatApiTicket',//JS-SDK使用权限签名获取
  /*押金收取*/
  'playerinfoget': '/payment/getPlayerInfoByCode',//根据赛事编号和选手参赛号获取选手信息
  'depositpay': '/payment/depositPayment',//押金支付
  /*赛事领物*/
  'assemcomme': '/assembleController/assembleComment',//获取领物说明
  'assemsearch': '/assembleController/player',//赛事检录选手搜素
  'assemdetail': '/assembleController/playerCheckInAssembleFlow',//获取选手详情和检录流程
}
let mapConfig = {
  token: 'pk.eyJ1IjoibGllcmlzbWUiLCJhIjoiY2lodmw4MWh1MDI2bnRnbTFxNmY3dDMxYyJ9.2hGe4xXi2Xsk_h_U6usr8w',
  style: {
    'version': 8,
    'name': 'RasterTiles',
    'sources': {
      'mapStyleLayer': {
        'type': 'raster',
        // 'tiles': ['//map.cmfun.cn:55555/googlemap-street-offset/vt/lyrs=y&hl=zh-CN&gl=CN&x={x}&y={y}&z={z}&s=Galileo&scale=2'],
        // 'tiles': ['http://map.orieange.com:55555/googlemap-satellite-HK/vt/lyrs=y&hl=zh-CN&gl=CN&x={x}&y={y}&z={z}&s=Galileo&scale=2'],
        // 'tiles': ['//map.orieange.com:55555/googlemap-street-offset/vt/lyrs=y&hl=zh-CN&gl=CN&x={x}&y={y}&z={z}&s=Galileo&scale=2'],
        // 'tiles': ['https://testmap.cmfun.cn/googlemap-street-offset/vt/lyrs=y&hl=zh-CN&gl=CN&x={x}&y={y}&z={z}&s=Galileo&scale=2'],
        'tiles': ['https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'],//Esri(WGS-84 地心坐标系)
        // 'tiles': ['http://t0.tianditu.gov.cn/img_c/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={x}&TILECOL={y}&tk=dd0cdf36a8779fb265bbfb9dd992ce56'],//天地图(WGS-84 地心坐标系)
        // 'tiles': ['http://t0.tianditu.com/vec_w/wmts?tk=e90d56e5a09d1767899ad45846b0cefd&SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=vec&STYLE=default&TILEMATRIXSET=w&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&FORMAT=tiles'],//天地图(WGS-84 地心坐标系)
        // 'tiles': [
          // '//t0.tianditu.com/img_w/wmts?tk=2c916e54bf9909661dec72eb2b66592f&SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&FORMAT=tiles',
          // 'https://t0.tianditu.gov.cn/img_w/wmts?tk=2c916e54bf9909661dec72eb2b66592f&SERVICE=WMTS&REQUEST=GetTile&LAYER=img&TILEMATRIXSET=w&FORMAT=tiles&VERSION=1.0.0&STYLE=default&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}',
        // ],//天地图(WGS-84 地心坐标系)
        'tileSize': 256
      },
      'tdtCva ': {
        'type': 'raster',
        'tiles': [
          'https://t0.tianditu.gov.cn/cva_w/wmts?tk=2c916e54bf9909661dec72eb2b66592f&SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cva&STYLE=default&TILEMATRIXSET=w&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&FORMAT=tiles'
        ],
        'tileSize': 256
      }
    },
    'glyphs': 'mapbox://fonts/wzz/{fontstack}/{range}.pbf',
    'layers': [
      {
        'id': 'mapStyleLayer',
        'type': 'raster',
        'source': 'mapStyleLayer',
        'paint': {'raster-fade-duration': 100}
      },
      /*{
        'id': 'tdtCva',
        'type': 'raster',
        'source': 'tdtCva',
        'paint': {'raster-fade-duration': 100}
      }*/
    ]
  },
  tiles: {
    // 'tiles2': ['https://testmap.cmfun.cn/googlemap-street-offset/vt/lyrs=y&hl=zh-CN&gl=CN&x={x}&y={y}&z={z}&s=Galileo&scale=2'],//google地图(国内：GCJ-02 火星坐标系，国外：WGS-84 地心坐标系)
    // 'tiles2': ['//t0.tianditu.com/img_w/wmts?tk=e90d56e5a09d1767899ad45846b0cefd&SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&FORMAT=tiles'],//google地图(国内：GCJ-02 火星坐标系，国外：WGS-84 地心坐标系)
    'tiles2': ['https://t0.tianditu.gov.cn/img_w/wmts?tk=2c916e54bf9909661dec72eb2b66592f&SERVICE=WMTS&REQUEST=GetTile&LAYER=img&TILEMATRIXSET=w&FORMAT=tiles&VERSION=1.0.0&STYLE=default&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}'],//google地图(国内：GCJ-02 火星坐标系，国外：WGS-84 地心坐标系)
    'tiles1': ['https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'],//Esri(WGS-84 地心坐标系)
    'tiles3': ['//ak.dynamic.t0.tiles.virtualearth.net/comp/ch/{quadkey}?mkt=zh-cn&it=A,G,L,LA&shading=hill&og=56&n=z'],//bing(GCJ-02 火星坐标系)
    'tiles4': [
      '//wprd01.is.autonavi.com/appmaptile?x={x}&y={y}&z={z}&style=6',
      '//wprd02.is.autonavi.com/appmaptile?x={x}&y={y}&z={z}&style=6',
      '//wprd03.is.autonavi.com/appmaptile?x={x}&y={y}&z={z}&style=6',
      '//wprd04.is.autonavi.com/appmaptile?x={x}&y={y}&z={z}&style=6',
    ],//高德地图(GCJ-02 火星坐标系)
  },
  tstyle: {//天地图：CGCS2000坐标系，与WGS-84差别不大，cm水平坐标精度以上没差
    'version': 8,
    'sources': {
      'tdtImg': {
        'type': 'raster',
        'tiles': [],//'//t0.tianditu.gov.cn/img_w/wmts?tk=985c987051be0ae61af3afed0dbadff5&SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&FORMAT=tiles'
        'tileSize': 256
      },
      'tdtCia': {
        'type': 'raster',
        'tiles': [],//'//t0.tianditu.gov.cn/cia_w/wmts?tk=985c987051be0ae61af3afed0dbadff5&SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cia&STYLE=default&TILEMATRIXSET=w&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&FORMAT=tiles'
        'tileSize': 256
      }
    },
    'layers': [
      {'id': 'tdtImg', 'type': 'raster', 'source': 'tdtImg'},
      {'id': 'tdtCia', 'type': 'raster', 'source': 'tdtCia'}
    ],
    'glyphs': 'mapbox://fonts/wzz/{fontstack}/{range}.pbf',
  },
  coortype: 2 //1:GCJ-02、2:WGS-84
}
let setMapConfig = function () {
  // let token = '985c987051be0ae61af3afed0dbadff5'
  let token = 'ba649be87521d5732b41eb3be53ec81e'
  let tdtUrl = '//t{s}.tianditu.gov.cn/'
  let subdomains = ['0', '1', '2', '3', '4', '5', '6', '7']
  let imgUrls = [], ciaUrls = []
  for (let i = 0; i < subdomains.length; i++) {
    let imgurl = tdtUrl.replace('{s}', subdomains[i]) + 'DataServer?T=img_w&x={x}&y={y}&l={z}&tk=' + token;
    let ciaurl = tdtUrl.replace('{s}', subdomains[i]) + 'DataServer?T=cia_w&x={x}&y={y}&l={z}&tk=' + token;
    imgUrls.push(imgurl);
    ciaUrls.push(ciaurl);
  }
  mapConfig.tstyle.sources.tdtImg.tiles = imgUrls
  mapConfig.tstyle.sources.tdtCia.tiles = ciaUrls
}
setMapConfig()

let ossConfig = {
  // region: 'oss-cn-shanghai',
  // accessKeyId: 'LTAI4GC1HDeXtsHgkBJZ6pZX',
  // accessKeySecret: 'zBwXmhc1s0gQZfGOsLKs4MuRmFMmB9',
  // bucket: 'jgwl-resource'
  region: 'oss-cn-beijing',
  accessKeyId: 'LTAI5tPST8LJbAaH5cVZvyyM',
  accessKeySecret: 'gWGtpPYOlOrmSSAgSAvsc5x78XWcsM',
  bucket: 'utrail-oss'
}
export default {server, mapConfig, ossConfig}
