import Config from '@/config/config'
import axios from 'axios'
// import VueAxios from 'vue-axios'

let util = {
  axios: function (aOptions) {
    let options = {
      method: aOptions.method ? aOptions.method : 'POST',
      baseURL: aOptions.baseURL ? aOptions.baseURL : Config.server.serverurl,
      url: aOptions.url ? aOptions.url : '',
      data: aOptions.data ? aOptions.data : {},
      success: aOptions.success ? aOptions.success : function (res) {
      },
      error: aOptions.error ? aOptions.error : function (err, errMes) {
      }
    }
    if (options.method === 'GET') {
      let links = []
      for(let i in options.data) {
        links.push(`${i}=${options.data[i]}`)
      }
      if (links.length > 0) {
        options.url += '?' + links.join('&')
      }
    }
    let isSuc = false
    axios({
      method: options.method,
      baseURL: options.baseURL,
      url: options.url,
      data: options.data,
      // withCredentials: true,
      // proxy: false
    }).then((res) => {
      if (res.data.code === '0') {
        isSuc = true
        if (typeof options.success === 'function') options.success(res.data)
      } else {
        if (typeof options.error === 'function') options.error(res.data, res.data.message)
      }
    }).catch((err) => {
      if (!isSuc) {
        if (typeof options.error === 'function') options.error(err, false)
      }
    })
  },
  add: function (a, b) {
    let self = this;
    let c, d, e;
    try {
      c = a.toString().split(".")[1].length;
    } catch (f) {
      c = 0;
    }
    try {
      d = b.toString().split(".")[1].length;
    } catch (f) {
      d = 0;
    }
    return e = Math.pow(10, Math.max(c, d)), (self.mul(a, e) + self.mul(b, e)) / e;
  },
  mul: function (a, b) {
    let c = 0,
      d = a.toString(),
      e = b.toString();
    try {
      c += d.split(".")[1].length;
    } catch (f) {
    }
    try {
      c += e.split(".")[1].length;
    } catch (f) {
    }
    return Number(d.replace(".", "")) * Number(e.replace(".", "")) / Math.pow(10, c);
  },
  //禁用浏览器返回
  fobiddenBack: function () {
    //防止页面后退
    history.pushState(null, null, document.URL);
    window.addEventListener('popstate', this.back_common)
  },
//启用浏览器返回
  enableBack: function () {
    history.go(-1);
    window.removeEventListener('popstate', this.back_common)
  },
  back_common: function () {
    history.pushState(null, null, document.URL);
  },
  hasClass(el, className) {
    const reg = new RegExp('(^|\\s)' + className + '(\\s|$)')
    return reg.test(el.className)
  },
  addClass(el, className) {
    /* istanbul ignore if */
    if (this.hasClass(el, className)) {
      return
    }

    const newClass = el.className.split(' ')
    newClass.push(className)
    el.className = newClass.join(' ')
  },
  removeClass(el, className) {
    /* istanbul ignore if */
    if (!this.hasClass(el, className)) {
      return
    }

    const reg = new RegExp('(^|\\s)' + className + '(\\s|$)', 'g')
    el.className = el.className.replace(reg, ' ')
  }
}
export default util
