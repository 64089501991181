import Vue from 'vue'
import './cube-ui'
import App from './App.vue'
import router from './router'
import Config from '@/config/config'
import util from '@/config/util'
import wxShare from '@/config/wxshare'
import axios from 'axios'
import VueAxios from 'vue-axios'

import './permission'
import "./assets/style.less"

Vue.config.productionTip = false
Vue.prototype.config = Config
Vue.prototype.util = util
Vue.prototype.wxShare = wxShare
Vue.config.ignoredElements = ['wx-open-launch-weapp']
Vue.use(VueAxios, axios)
// FastClick.attach(document.body)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
