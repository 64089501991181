import router from './router'
import {getSession, setSession, getLocal, setLocal} from '@/config/auth'
import util from '@/config/util'

let getUserInfo = function(CODE, callback) {
	let userInfo = getLocal('wechat_user')
	userInfo = userInfo ? JSON.parse(userInfo) : false
	util.axios({
		url: '/personalCenter/getWechatInfoByCode',
		data: {code: CODE},
		success: function (res) {
			let User = res.data
			if (User) {
				User.openID = User.openid ? User.openid : userInfo.openID
				User.weChatNickName = User.weChatNickName ? User.weChatNickName : userInfo.weChatNickName
				User.weChatImgUrl = User.weChatImgUrl ? User.weChatImgUrl : userInfo.weChatImgUrl
				User.imUserSig = User.imUserSig ? User.imUserSig : userInfo.imUserSig
			}
			setLocal('wechat_user', JSON.stringify(User))
			callback()
		},
		error: function () {
			callback()
			if (!userInfo||!userInfo.openID) {
				alert('授权失败！')
			}
		}
	})
}

let getPath = function (callback) {
  if (location.href.indexOf('path=') > -1) {
    callback(false)
    return false
  }
	let auth = getSession('authority')
	if (!auth) {
    let href = location.href
    let search = href.split('state=')
    search = search.length > 1 ? search[1] : false
    let path = false
    if (search) {
      search = search.split('#')[0]
      let pathurl = search.split('%')[0]
      let patharr = pathurl.split('i')
      let pathname = patharr[0] === 'match' ? '' : patharr[0]
      path = patharr.length > 1 ? pathname + '/' + patharr[1] : pathname
    }

    let codesearch = href.split('code=')
    codesearch = codesearch.length > 1 ? codesearch[1] : ''
    let code = codesearch.split('&')[0]
    code = code.split('#')[0]
    code = code.split('/')[0]

    setSession('authority', JSON.stringify({path:path, code:code}))
    if (code) {
      let lastcode = getSession('code')
      if (lastcode && lastcode === code){
        callback(path)
      } else {
        getUserInfo(code, function () {
          callback(path)
        })
      }
    } else {
      callback(path)
    }
	} else {
    callback(false)
	}
}

router.beforeEach((to, from, next) => {
  if (window.msgInterval) {
    window.clearInterval(window.msgInterval)
    window.msgInterval = undefined
  }
  if (window.frashInterval) {
    window.clearInterval(window.frashInterval)
    window.frashInterval = undefined
  }
  if (window.timeInterval) {
    window.clearInterval(window.timeInterval)
    window.timeInterval = undefined
  }
  if (window.interval) {
    window.clearInterval(window.interval)
    window.interval = undefined
  }
  if (window.cdinterval) {
    window.clearInterval(window.cdinterval)
    window.cdinterval = undefined
  }
  if (!window.hasback && (from.path.indexOf('broadcast') > -1 || from.path.indexOf('live') > -1)&&(to.path.indexOf('broadcast') > -1 || to.path.indexOf('live') > -1)) {
    window.haslive = true
  } else {
    window.haslive = false
  }

  getPath(function (path) {
    if (path !== false) {
      next('/' + path)
    } else {
      next()
    }
  })
})

router.afterEach(() => {})
